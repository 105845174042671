export enum PaymentStatus {
  Pending = "pending",
  Paid = "paid",
  Canceled = "canceled",
  Failed = "failed",
}

export enum RefundStatus {
  Pending = "pending",
  Refunded = "refunded",
  Canceled = "canceled",
  Failed = "failed",
}

export interface PaymentListItem {
  id: string | number;
  created_at: string | null;
  status: string;
  paid_time: string | null;
  payment_order_no: string;
  customer_name: string;
  amount: string;
  currency: string;
  description: string;
  pay_link: string;
}

export interface RefundListItem {
  id: number | string;
  platform_id: number | string;
  refund_order_no: string;
  payment_order_no: string;
  sales_order_no: string;
  customer_name: string;
  status: string;
  amount: number | string;
  currency: string;
  refund_time: string | null;
  reason: string;
  __show: {
    platform: string;
    status: string;
  };
}

interface Amount {
  amount: number | string;
  currency: string;
}

export interface PaymentLogItem {
  id: number | string;
  payment_id: number | string;
  created_at: string | null;
  updated_at: string | null;
  payment_method: string;
  payment_request_no: string;
  pay_trade_no: string;
  pay_platform_trade_no: string;
  amount: number | string;
  currency: string;
  paid_time: string | null;
  expiry_time: string | null;
  status: string;
  __show: {
    payment_method: string;
    status: string;
  };
}

export const paymentLogItemData: PaymentLogItem = {
  id: 0,
  payment_id: 0,
  created_at: "",
  updated_at: "",
  payment_method: "",
  payment_request_no: "",
  pay_trade_no: "",
  pay_platform_trade_no: "",
  amount: "",
  currency: "",
  paid_time: "",
  expiry_time: "",
  status: "",
  __show: {
    payment_method: "",
    status: "",
  },
};

export interface RelatedRefundItem {
  id: number | string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  created_uid: number | string;
  updated_uid: number | string;
  deleted_uid: number | string;
  owner_id: number | string;
  platform_id: number | string;
  refund_order_no: string;
  payment_order_no: string;
  sales_order_no: string;
  status: string;
  amount: number | string;
  currency: string;
  reason: string;
  refund_time: string | null;
  created_by: string;
  updated_by: string;
  __show: {
    status: string;
  };
}

export const relatedRefundData: RelatedRefundItem = {
  id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  created_uid: 0,
  updated_uid: 0,
  deleted_uid: 0,
  owner_id: 0,
  platform_id: 0,
  refund_order_no: "",
  payment_order_no: "",
  sales_order_no: "",
  status: "",
  amount: "",
  currency: "",
  reason: "",
  refund_time: "",
  created_by: "",
  updated_by: "",
  __show: {
    status: "",
  },
};

export interface PaymentOverview {
  id: number | string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  created_uid: number | string;
  updated_uid: number | string;
  deleted_uid: number | string;
  created_by: string;
  updated_by: string;
  owner_id: number | string;
  platform_id: number | string;
  payment_order_no: string;
  reference_order_no: string;
  status: PaymentStatus | string;
  paid_time: string | null;
  customer_id: number | string;
  description: string;
  amount: number | string;
  currency: string;
  customer_email: string;
  ocps_customer_id: number | string;
  customer_name: string;
  pay_link: string;
  payment_method: string;
  payment_request_no: string;
  pay_trade_no: string;
  pay_platform_trade_no: string;
  __show: {
    platform: string;
    status: string;
    payment_method: string;
  };
  refundAbleAmount: Amount;
  payment_items: Array<PaymentLogItem>;
  relation_refund: Array<RelatedRefundItem>;
}

export const paymentOverviewData: PaymentOverview = {
  id: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  created_uid: 0,
  updated_uid: 0,
  deleted_uid: 0,
  created_by: "",
  updated_by: "",
  owner_id: 0,
  platform_id: "",
  payment_order_no: "",
  reference_order_no: "",
  status: "",
  paid_time: "",
  customer_id: 0,
  description: "",
  amount: "",
  currency: "",
  customer_email: "",
  ocps_customer_id: 0,
  customer_name: "",
  pay_link: "",
  payment_method: "",
  payment_request_no: "",
  pay_trade_no: "",
  pay_platform_trade_no: "",
  __show: {
    platform: "",
    status: "",
    payment_method: "",
  },
  refundAbleAmount: {
    amount: "",
    currency: "",
  },
  payment_items: [],
  relation_refund: [],
};

export interface RefundLogItem {
  id: number | string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  created_uid: number | string;
  updated_uid: number | string;
  deleted_uid: number | string;
  platform_id: number | string;
  refund_id: number | string;
  refund_method: string;
  refund_request_no: string;
  pay_trade_no: string;
  pay_platform_trade_no: string;
  refund_trade_no: string;
  refund_platform_trade_no: string;
  status: string;
  amount: number | string;
  currency: string;
  refund_time: string | null;
  refund_error_msg: string;
  __show: {
    refund_method: string;
    status: string;
  };
}

export const refundLogItemData: RefundLogItem = {
  id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  created_uid: 0,
  updated_uid: 0,
  deleted_uid: 0,
  platform_id: 0,
  refund_id: 0,
  refund_method: "",
  refund_request_no: "",
  pay_trade_no: "",
  pay_platform_trade_no: "",
  refund_trade_no: "",
  refund_platform_trade_no: "",
  status: "",
  amount: "",
  currency: "",
  refund_time: "",
  refund_error_msg: "",
  __show: {
    refund_method: "",
    status: "",
  },
};

export interface RefundOverview {
  id: number | string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: null;
  created_uid: number | string;
  updated_uid: number | string;
  deleted_uid: number | string;
  owner_id: number | string;
  customer_name: string;
  platform_id: number | string;
  refund_order_no: string;
  payment_id: number | string;
  payment_order_no: string;
  sales_order_no: string;
  status: RefundStatus | string;
  amount: number | string;
  currency: string;
  reason: string;
  refund_time: string | null;
  __show: {
    platform: string;
    status: string;
    refund_method: string;
  };
  created_by: string;
  updated_by: string;
  refund_trade_no: string;
  refund_platform_trade_no: string;
  refund_method: string;
  refund_items: Array<RefundLogItem>;
}

export const refundOverviewData: RefundOverview = {
  id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  created_uid: 0,
  updated_uid: 0,
  deleted_uid: 0,
  owner_id: 0,
  customer_name: "",
  platform_id: 0,
  refund_order_no: "",
  payment_id: 0,
  payment_order_no: "",
  sales_order_no: "",
  status: "",
  amount: "",
  currency: "",
  reason: "",
  refund_time: "",
  __show: {
    platform: "",
    status: "",
    refund_method: "",
  },
  created_by: "",
  updated_by: "",
  refund_trade_no: "",
  refund_platform_trade_no: "",
  refund_method: "",
  refund_items: [],
};
