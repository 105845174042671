/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-07 11:30:15
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-07 13:40:25
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType, BadgeStyle } from "../type/common";
import { PaymentStatus, RefundStatus } from "../interface/payment";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getPaymentModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setPaymentTitleAndPath(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setPaymentTitleAndPath = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/payment-management/";
  result.parentName = language("payment.paymentManagement");
  if (route.path.match("refund")) {
    strName = language("payment.refund");
    listPath += "refund";
  } else if (route.path.match("payment")) {
    strName = language("payment.payment");
    listPath += "payment";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getPaymentDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "payment-management-payment-overview":
    case "payment-management-refund-overview":
      result.currentName = language("common.overview");
      setPaymentTitleAndPath(language, route, result, router);
      break;
    case "payment-management-payment-logs":
    case "payment-management-refund-logs":
      result.currentName = language("common.logs");
      setPaymentTitleAndPath(language, route, result, router);
      break;
  }
  return result;
};

/**
 * @description: 获取支付状态（样式）
 * @return CommonStatus
 */
export const getPaymentStatus = (status) => {
  let statusClass = "";
  switch (status) {
    case PaymentStatus.Pending:
      statusClass = BadgeStyle.LightWarning;
      break;
    case PaymentStatus.Paid:
      statusClass = BadgeStyle.LightSuccess;
      break;
    case PaymentStatus.Canceled:
      statusClass = BadgeStyle.Secondary;
      break;
    case PaymentStatus.Failed:
      statusClass = BadgeStyle.LightDanger;
      break;
    default:
      statusClass = BadgeStyle.LightWarning;
      break;
  }
  return statusClass;
};

/**
 * @description: 获取退款状态（样式）
 * @return CommonStatus
 */
export const getRefundStatus = (status) => {
  let statusClass = "";
  switch (status) {
    case RefundStatus.Pending:
      statusClass = BadgeStyle.LightWarning;
      break;
    case RefundStatus.Refunded:
      statusClass = BadgeStyle.LightSuccess;
      break;
    case RefundStatus.Canceled:
      statusClass = BadgeStyle.Secondary;
      break;
    case RefundStatus.Failed:
      statusClass = BadgeStyle.LightDanger;
      break;
    default:
      statusClass = BadgeStyle.LightWarning;
      break;
  }
  return statusClass;
};
