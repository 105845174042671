/*
 * @Descripttion:
 * @Author: xiang.gao 1543865938
 * @Date: 2022-06-09 16:36:06
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-27 12:20:40
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { Modal } from "bootstrap";
import moment from "moment";
import momentTimezone from "moment-timezone";
import fileDownload from "js-file-download";
import Swal from "sweetalert2/dist/sweetalert2.js";
import config from "@/core/config/ApiConfig";
import { CommonDateType, ProjetcsAll } from "../type/common";
import { CommonLanguageType } from "../type/common";
import { CommonModuleRouter } from "../interface/common";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getDocumentsDetailModule, getDocumentsModule } from "./documents";
import { getSalesOrderDetailModule, getSalesOrderModule } from "./salesOrder";
import {
  getMBOrderRoutingDetailModule,
  getMBOrderRoutingModule,
} from "./mbOrderRouting";
import {
  getMerchantOrderDetailModule,
  getMerchantOrderModule,
} from "./merchantOrder";
import {
  getDoubleSaleOrderDetailModule,
  getDoubleSaleOrderModule,
} from "./doubleSaleOrder";
import {
  getTransportManagementDetailModule,
  getTransportManagementModule,
} from "./transportManagement";
import {
  getWarehouseManagementDetailModule,
  getWarehouseManagementModule,
} from "./warehouseManagement";
import {
  getDeclarationManagementDetailModule,
  getDeclarationManagementModule,
} from "./declarationManagement";
import {
  getRiskAndExceptionDetailModule,
  getRiskAndExceptionModule,
} from "./riskAndException";
import {
  getOrganisationAndFacilityDetailModule,
  getOrganisationAndFacilityModule,
} from "./organisationAndFacility";
import { getPaymentDetailModule, getPaymentModule } from "./paymentManagement";
import { computed, nextTick } from "vue";
import { pinyin } from "pinyin-pro";

/**
 * @description: 获取列表
 * @return CommonModuleRouter
 */
export const getModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router?: Router
): CommonModuleRouter => {
  if (route.path.match("/sales-order/")) {
    return router
      ? getSalesOrderDetailModule(language, route, router)
      : getSalesOrderModule(language, route);
  } else if (route.path.match("/mb-order-routing/")) {
    return router
      ? getMBOrderRoutingDetailModule(language, route, router)
      : getMBOrderRoutingModule(language, route);
  } else if (route.path.match("/merchant-order/")) {
    return router
      ? getMerchantOrderDetailModule(language, route, router)
      : getMerchantOrderModule(language, route);
  } else if (route.path.match("/double-sale-order/")) {
    return router
      ? getDoubleSaleOrderDetailModule(language, route, router)
      : getDoubleSaleOrderModule(language, route);
  } else if (route.path.match("/transport-management/")) {
    return router
      ? getTransportManagementDetailModule(language, route, router)
      : getTransportManagementModule(language, route);
  } else if (route.path.match("/warehouse-management/")) {
    return router
      ? getWarehouseManagementDetailModule(language, route, router)
      : getWarehouseManagementModule(language, route);
  } else if (route.path.match("/declaration-management/")) {
    return router
      ? getDeclarationManagementDetailModule(language, route, router)
      : getDeclarationManagementModule(language, route);
  } else if (route.path.match("/documents/")) {
    return router
      ? getDocumentsDetailModule(language, route, router)
      : getDocumentsModule(language, route);
  } else if (route.path.match("/risk-and-exception/")) {
    return router
      ? getRiskAndExceptionDetailModule(language, route, router)
      : getRiskAndExceptionModule(language, route);
  } else if (route.path.match("/organisation-and-facility/")) {
    return router
      ? getOrganisationAndFacilityDetailModule(language, route, router)
      : getOrganisationAndFacilityModule(language, route);
  } else if (route.path.match("/payment-management/")) {
    return router
      ? getPaymentDetailModule(language, route, router)
      : getPaymentModule(language, route);
  }
  return router
    ? getSalesOrderDetailModule(language, route, router)
    : getSalesOrderModule(language, route);
};

/**
 * @description: 设置BCN
 * @return CommonModuleRouter
 */
export const setModuleBCN = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router?: Router
): void => {
  if (router) {
    const moudleResult = getModule(language, route, router);
    setCurrentPageBreadcrumbs(moudleResult.currentName, [
      {
        name: moudleResult.parentName,
        path: "",
      },
      {
        name: moudleResult.listName,
        path: moudleResult.listPath,
      },
    ]);
  } else {
    const moudleResult = getModule(language, route);
    setCurrentPageBreadcrumbs(moudleResult.currentName, [
      { name: moudleResult.parentName, path: "" },
    ]);
  }
};

/**
 * @description: 处理文件size
 * @param {number} size
 * @return {*}
 */
export const substringFileName = (fileName: string, type = 0): string => {
  if (type === 0) {
    return fileName.substring(0, fileName.lastIndexOf("."));
  }
  return fileName.substring(fileName.lastIndexOf("."));
};

/**
 * @description: 处理文件size
 * @param {number} size
 * @return {*}
 */
export const bytesToSize = (fileSize: number): string => {
  let temp: string | number = fileSize;
  if (temp < 1024) {
    return temp + "B";
  } else if (fileSize < 1024 * 1024) {
    temp = fileSize / 1024;
    return temp.toFixed(2) + "KB";
  } else if (fileSize < 1024 * 1024 * 1024) {
    temp = fileSize / (1024 * 1024);
    return temp.toFixed(2) + "MB";
  } else {
    temp = fileSize / (1024 * 1024 * 1024);
    return temp.toFixed(2) + "GB";
  }
};

/**
 * @description: 返回上一页（没有上一页时返回指定页面）
 * @param {Router} router
 * @param {string} path
 * @return {*}
 */
export const commonBackToList = (router: Router, path: string): void => {
  if (window.history.state.back) {
    router.back();
  } else {
    router.replace(path);
  }
};

/**
 * @description: 获取不同地区图标
 * @param {String} value
 * @return {*}
 */
export const commonCountryFlagPath = (value: string): string => {
  let url = "";
  switch (value) {
    case "MO":
      url = "media/icons/flat/icon-macao.svg";
      break;
    case "CN":
      url = "media/icons/flat/icon-china.svg";
      break;
    case "HK":
      url = "media/icons/flat/icon-hk.svg";
      break;
    case "DE":
      url = "media/icons/flat/icon-eu.svg";
      break;
    default:
      break;
  }
  return url;
};

/**
 * @description: 跳转项目页面（指定项目页面）
 * @param {Router} router
 * @param {string} path
 * @return {*}
 */
export const commonGoToPath = (path: string, type?: ProjetcsAll): string => {
  let url = "";
  const urlString = "/#";
  switch (type) {
    case "Merchant":
      url = config.merchantUrl + urlString + path;
      break;
    case "Distribution":
      url = config.distributionUrl + urlString + path;
      break;
    case "Fulfillment":
      url = config.fulfillmentUrl + urlString + path;
      break;
    case "Settlement":
      url = config.settlementUrl + urlString + path;
      break;
    case "PIM":
      url = config.pimUrl + urlString + path;
      break;
    case "CDN":
      url = config.cdnUrl + path;
      break;
    default:
      url = config.baseUrl + urlString + path;
  }
  return url;
};

export const getDiffDays = (endDate: CommonDateType) => {
  const eDate = momentTimezone(endDate)
    .tz("Europe/Berlin")
    .format("YYYY-MM-DD");
  const nDate = momentTimezone(new Date())
    .tz("Europe/Berlin")
    .format("YYYY-MM-DD");
  return moment(eDate).diff(moment(nDate), "days");
};

export const getLateDays = (endDate: CommonDateType) => {
  const eDate = momentTimezone(endDate)
    .tz("Europe/Berlin")
    .format("YYYY-MM-DD");
  const nDate = momentTimezone(new Date())
    .tz("Europe/Berlin")
    .format("YYYY-MM-DD");
  return moment(nDate).diff(moment(eDate), "days");
};

export const modalShowListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.addEventListener("show.bs.modal", callback);
};

const showServerErrorMsg = (res) => {
  const errorMsg = res.data;
  let htmlMsg = "";
  if (errorMsg) {
    for (const key in errorMsg) {
      htmlMsg += errorMsg[key][0] + "<br/>";
    }
  }
  if (res.sub_code.indexOf("FORM-VALIDATION") != -1) {
    //服务端数据验证错误
    Swal.fire({
      title: res.sub_msg,
      icon: "error",
      html: htmlMsg,
    });
  } else {
    //服务端其他错误
    Swal.fire({
      title: res.msg,
      text: res.sub_msg,
      icon: "error",
    });
  }
};

/**
 * @description: 公共下载文件流方法
 * @param {*} data
 * @return {*}
 */
export const commonExportFile = (data, name?) => {
  const fileReader = new FileReader();
  fileReader.onload = function () {
    try {
      // 说明是普通对象数据，后台转换失败
      const jsonData = JSON.parse(this.result as string);
      showServerErrorMsg(jsonData);
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      const disposition = data.headers["content-disposition"];
      if (!name) {
        const fileNameString = disposition.split("filename=")[1];
        let fileName = fileNameString.split('"').join("").split("'").join("");
        if (fileName.indexOf("filename*=utf-8") > -1) {
          fileName = decodeURI(fileName.split("filename*=utf-8")[1]);
        }
        fileName = decodeURIComponent(fileName);
        fileDownload(data.data, decodeURI(fileName));
      } else {
        const fileName = decodeURIComponent(name);
        fileDownload(data.data, decodeURI(fileName));
      }
    }
  };
  fileReader.readAsText(data.data);
};

/**
 * @description: 公共查看文件流方法
 * @param {*} data
 * @return {*}
 */
export const commonPreviewFile = (childWin, name, data, isNormal = true) => {
  try {
    let fileURL = "",
      count = 0;
    const blob = new Blob([data], { type: "application/pdf" });
    if (window.webkitURL != undefined) {
      // webkit or chrome
      fileURL = window.webkitURL.createObjectURL(blob);
    } else if (window.URL != undefined) {
      // mozilla(firefox)
      fileURL = window.URL.createObjectURL(blob);
    }
    if (isNormal) {
      childWin = window.open(
        fileURL,
        name,
        "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      ) as Window;
    } else {
      childWin = window.open(
        fileURL,
        name,
        "height=700, width=600, top=50, left=50, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      ) as Window;
    }
    childWin.onunload = function () {
      if (count > 0) {
        if (window.webkitURL != undefined) {
          // webkit or chrome
          window.webkitURL.revokeObjectURL(fileURL);
        } else if (window.URL != undefined) {
          // mozilla(firefox)
          window.URL.revokeObjectURL(fileURL);
        }
      }
      count++;
      return null;
    };
  } catch (error) {
    // console.log(error);
  }
};

export const CommonArrayToString = (value: Array<number | string>) => {
  let string = "";
  value.forEach((item, index) => {
    if (index !== 0) {
      string += ", " + item;
    } else {
      string += item;
    }
  });
  return string;
};

export const commonDefaultDate = (date): string => {
  return moment(date).add(12, "hours").toISOString();
};

export const commonDefaultAddDate = (date, day: number): string => {
  return moment(date).add(12, "hours").add(day, "days").toISOString();
};

export const commonChangeFilterDate = (
  e,
  form,
  data: string,
  type?: string
): void => {
  // UTC 德国时区
  const value = moment.tz(e, "Europe/Berlin").startOf("days").utc().format();
  // UTC 0时区
  // const value = moment(e, "YYYY-MM-DD").utc(true).toISOString();
  if (type) {
    form[data][type] = value;
  } else {
    form[data] = value;
  }
};

export const commonChangeFilterAddDate = (
  date,
  num = 2,
  type: any = "days"
): string => {
  // UTC 德国时区
  return moment
    .tz(date, "Europe/Berlin")
    .add(num, type)
    .startOf("days")
    .utc()
    .format();
  // UTC 0时区
  return moment(date, "YYYY-MM-DD")
    .add(num, type)
    .startOf("days")
    .utc(true)
    .toISOString();
};

export const commonChangeDefaultDate = (
  e,
  form,
  data: string,
  type?: string
): void => {
  const value = moment(e, "YYYY-MM-DD").add(12, "hours").toISOString();
  if (type) {
    form[data][type] = value;
  } else {
    form[data] = value;
  }
};

export const commonChangeDefaultDateTime = (
  e,
  form,
  data: string,
  type?: string
): void => {
  const value = moment(e, "YYYY-MM-DD HH:mm:ss").add(12, "hours").toISOString();
  if (type) {
    form[data][type] = value;
  } else {
    form[data] = value;
  }
};

export const formatUtcDate = (date: CommonDateType, format = "DD.MM.YYYY") => {
  return date ? moment(date).format(format) : "";
};

export const formatDate = (date: CommonDateType, format = "YYYY-MM-DD") => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).tz("Europe/Berlin").format(format) : "";
};

export const formatDateOriginTimeZone = (
  date: CommonDateType,
  format = "YYYY-MM-DD"
) => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).format(format) : "";
};

export const formatDateTime = (
  date: CommonDateType,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).tz("Europe/Berlin").format(format) : "";
};

/**
 * @description: 检查手机号
 * @param {string} mobile
 * @return boolean
 */
export const testMobile = (mobile: string): boolean => {
  const mobileRegexp = /^1[23456789]\d{9}$/;
  return mobileRegexp.test(mobile);
};

/**
 * @description: 检查身份证号
 * @param {string} IDCard
 * @return boolean
 */
export const testIDCard = (IDCard: string): boolean => {
  const idCardRegexp =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  return idCardRegexp.test(IDCard);
};

/**
 * @description: 只能输入数字和英文字母
 * @param {string} value
 * @return string
 */
export const filterSpecialSymbolsChineses = (value: string): string => {
  return value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, "");
};

/**
 * @description: 过滤特殊符号
 * @param {string} value
 * @return string
 */
export const filterSpecialSymbols = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9\u4E00-\u9FA5]/g, "");
};

/**
 * @description: 过滤中文
 * @param {string} value
 * @return string
 */
export const filterChineses = (value: string): string => {
  return value.replace(/[\u4e00-\u9fa5/\s+/]/g, "");
};

/**
 * @description:
 * @param {HTMLElement} modalEl
 * @param {*} callback
 * @return {*}
 */
export const modalKeyDownListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.addEventListener("keydown", callback);
};

/**
 * @description:
 * @return {*}
 */
export const modalRemoveKeyDownListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.removeEventListener("keydown", callback);
};

export const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = new Modal(modalEl);
  myModal.show();
};
/**
 * @description: 判断是否是手机端，如果是，返回true
 * @return {*}
 */
export const isMobile = window.matchMedia("(max-width: 576px)"); // max-width与全局样式要统一
// 判断是否是手机端，如果是，返回true
// export const isMobile = () => {
//   const isFlag = navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   );
//   return isFlag;
// };

/**
 * @description: 处理ios移动端el-select聚焦bug
 * @return {*}
 */
export const removeReadonly = (async, currentInstance) => {
  nextTick(() => {
    if (!async) {
      // ios 手机有延迟问题
      setTimeout(() => {
        Object.keys(currentInstance.ctx._.refs).forEach((item) => {
          if (item.match("select")) {
            currentInstance.ctx._.refs[item]?.$el
              .querySelector(".el-input__inner")
              .removeAttribute("readonly");
          }
        });
      }, 200);
    }
  });
};

/**
 * @description: 字符串首字母转大写
 */
export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * @description: 判断字符串是否含有中文
 */
export const containsChinese = (str) => {
  const chineseRegex = /[\u4e00-\u9fa5]/;
  return chineseRegex.test(str);
};

/**
 * @description: 汉字转拼音
 * @return Pinyin
 */
export const chineseToPinyin = (chineseText) => {
  if (!chineseText) return "";
  return pinyin(chineseText, { toneType: "none" });
};

/**
 * @description: 名转拼音
 * @return Pinyin
 */
export const firstnameToPinyin = (firstname) => {
  if (!firstname) return "";
  if (!containsChinese(firstname)) {
    return firstname;
  } else {
    const name = chineseToPinyin(firstname).replace(" ", "");
    return capitalizeFirstLetter(name);
  }
};

/**
 * @description: 姓转拼音
 * @return Pinyin
 */
export const lastnameToPinyin = (lastname) => {
  if (!lastname) return "";
  if (!containsChinese(lastname)) {
    return lastname;
  } else {
    return capitalizeFirstLetter(chineseToPinyin(lastname));
  }
};
